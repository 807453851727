import React, {useState} from "react";
import {Tooltip} from "react-tippy";

import 'react-tippy/dist/tippy.css';

export const TOOLTIP_INDICATOR = "ⓘ";

export function MHTooltip(props) {
    const {
        header,
        body,
        footer,
        position,
        interactive,
        icon,
        inheritFontSize,
        noLeftMargin,
        cursor
    } = props;

    const [active, setActive] = useState(false);

    let headerComponent;
    if (header) {
        headerComponent = (
            <>
                <span
                    style={{
                        textDecoration: 'underline'
                    }}
                >
                    {header}
                </span>:&nbsp;
            </>
        )
    }

    let bodyComponent;
    if (body) {
        bodyComponent = (
            <>
                {body}&nbsp;
            </>
        )
    }

    let footerComponent;
    if (footer) {
        footerComponent = (
            <span
                style={{
                    fontStyle: 'italic'
                }}
            >
                {footer}
            </span>
        )
    }

    return (
        <Tooltip
            html={
                <div
                    style={{
                        pointerEvents: active ? undefined : 'none'
                    }}
                >
                    {headerComponent}
                    {bodyComponent}
                    {footerComponent}
                </div>
            }
            position={position || 'bottom'}
            arrow={true}
            animateFill={false}
            hideOnClick={false}
            distance={4}
            interactive={interactive}
            onShown={() => {
                setActive(true);
            }}
            onHide={() => {
                setActive(false);
            }}
            className="tooltip"
            style={{
                cursor: cursor || 'help',
                marginLeft: noLeftMargin || 4
            }}
        >
            <div
                className="mh-tooltip-target"
                style={{
                    display: 'inline-block',
                    fontSize: inheritFontSize || 16,
                    fontWeight: 'initial'
                }}
            >
                {icon || TOOLTIP_INDICATOR}
            </div>
        </Tooltip>
    );
}

export default MHTooltip;
