import moment from "moment";
import React, {useEffect, useState} from 'react';
import {Flipped, Flipper} from "react-flip-toolkit";
import ReactGA from "react-ga4";
import {Link, useParams} from "react-router-dom";
import {AutoTextSize} from 'auto-text-size';

import PageLoadingSpinner from "./PageLoadingSpinner";
import RouteCard from "./RouteCard";
import SearchBox from "./SearchBox";
import SortByOptions from "./SortByOptions";
import SystemCard from "./SystemCard";
import {getPerformanceScore, getPerformanceScoreColorName} from "./Util";

export default function SystemRoutes() {

    const [systemName] = useState(useParams().systemName);

    const [lastUpdated, setLastUpdated] = useState(null);
    const [rawPerformanceData, setRawPerformanceData] = useState(null);

    const [sortBy, setSortBy] = useState('observedPercentOnTimeVisitsByHeadway');
    const [sortDirection, setSortDirection] = useState("asc");
    const [routeNameFilter, setRouteNameFilter] = useState("");

    const [initialized, setInitialized] = useState(false);

    function toggleSortDirection() {
        if (sortDirection === "asc") {
            setSortDirection("desc");
        } else {
            setSortDirection("asc");
        }
    }

    useEffect(() => {
        // reset scroll
        window.scrollTo(0, 0);

        // on mount
        ReactGA.initialize('G-ZN33QTNMS7');
        ReactGA.pageview(window.location.pathname);

        return () => {
            // on unmount
            const timerId = localStorage.getItem("timerId");
            if (timerId) {
                clearTimeout(parseInt(timerId, 10));
            }
        };
    }, []);

    useEffect(() => {
        async function fetchData(shouldFetchData) {
            let refreshInterval;
            try {
                if (shouldFetchData) {
                    const timerId = localStorage.getItem("timerId");
                    if (timerId) {
                        clearTimeout(parseInt(timerId, 10));
                    }

                    const res = await fetch(`/api/v1/system/${encodeURIComponent(systemName)}/performance/live?shouldIncludeRouteData=true`);
                    const text = await res.text();
                    const json = JSON.parse(text);

                    setLastUpdated(json['lastUpdated']);
                    setRawPerformanceData(json);
                }

                refreshInterval = 30000;
            } catch (err) {
                refreshInterval = 5000;
            } finally {
                if (shouldFetchData && refreshInterval) {
                    const timerId = setTimeout(fetchData, refreshInterval, true);
                    localStorage.setItem("timerId", timerId.toString());
                }

                setInitialized(true);
            }
        }

        setTimeout(fetchData, 0, !initialized);
    }, [systemName, initialized]);

    let numResultsMessage;
    let recentnessData;
    let searchAndFilterBar;
    let pageContent;

    let systemPerformanceScoreColorName;
    let systemCard;

    if (!rawPerformanceData) {
        pageContent = (
            <PageLoadingSpinner />
        );

        systemPerformanceScoreColorName = getPerformanceScoreColorName(null);
    } else {
        // filter raw route performance data
        const filteredRoutePerformanceDataArray = [];
        for (const [routeId, routePerformanceData] of Object.entries(rawPerformanceData['routes'])) {
            if (routeNameFilter) {
                if (routeId.toUpperCase().includes(routeNameFilter)) {
                    filteredRoutePerformanceDataArray.push(routePerformanceData);
                }
            } else {
                filteredRoutePerformanceDataArray.push(routePerformanceData);
            }
        }

        // sort filtered route performance data
        const sortedFilteredRoutePerformanceDataArray = filteredRoutePerformanceDataArray.sort((a, b) => {
            if (a[sortBy] === null) {
                return 1;
            } else if (b[sortBy] === null) {
                return -1;
            } else if (a[sortBy] === b[sortBy]) {
                // fall back to sorting by route name in ascending order
                return a['routeId'].localeCompare(b['routeId'], 'en', {numeric: true});
            } else if (sortDirection === "asc") {
                if (sortBy === 'routeId') {
                    return b[sortBy].localeCompare(a[sortBy], 'en', {numeric: true});
                } else {
                    return b[sortBy] - a[sortBy];
                }
            } else if (sortDirection === "desc") {
                if (sortBy === 'routeId') {
                    return a[sortBy].localeCompare(b[sortBy], 'en', {numeric: true});
                } else {
                    return a[sortBy] - b[sortBy];
                }
            } else {
                return 0;
            }
        });

        // show "no matching cards found" message if performance cards are empty and there's a filter applied
        const routeCards = [];
        let numResultsFoundText = "No routes found";

        // if there's a filter applied and there are any results, change the message to indicate how many
        if (routeNameFilter.length > 0) {
            if (sortedFilteredRoutePerformanceDataArray.length > 0) {
                numResultsFoundText = `Showing ${sortedFilteredRoutePerformanceDataArray.length} route${(sortedFilteredRoutePerformanceDataArray.length !== 1) ? "s" : ""}`;
            }

            numResultsMessage = (
                <div className="has-text-centered" style={{height: '50px'}}>
                    <em>{numResultsFoundText} that match{(sortedFilteredRoutePerformanceDataArray.length === 1) ? "es" : ""} <strong>"{routeNameFilter}"</strong></em>
                </div>
            );
        }

        for (const routePerformance of sortedFilteredRoutePerformanceDataArray) {
            const routeId = routePerformance['routeId'];

            const percentOnTimeVisitsByHeadway = (routePerformance['observedPercentOnTimeVisitsByHeadway'] != null) ? Math.round(routePerformance['observedPercentOnTimeVisitsByHeadway']) : null;
            const percentBunchedVisits = (routePerformance['observedPercentBunchedVisits'] != null) ? Math.round(routePerformance['observedPercentBunchedVisits']) : null;
            const percentLateVisitsByHeadway = (routePerformance['observedPercentLateVisitsByHeadway'] != null) ? Math.round(routePerformance['observedPercentLateVisitsByHeadway']) : null;
            const medianLatenessByHeadway = (routePerformance['observedMedianLatenessByHeadway'] != null) ? Math.round(routePerformance['observedMedianLatenessByHeadway']) : null;
            const medianObservedHeadway = (routePerformance['observedMedianObservedHeadway'] != null) ? Math.round(routePerformance['observedMedianObservedHeadway']) : null;
            const medianScheduledHeadway = (routePerformance['medianScheduledHeadway'] != null) ? Math.round(routePerformance['medianScheduledHeadway']) : null;
            const percentOnTimeVisitsBySchedule = (routePerformance['observedPercentOnTimeVisitsBySchedule'] != null) ? Math.round(routePerformance['observedPercentOnTimeVisitsBySchedule']) : null;
            const percentEarlyVisitsBySchedule = (routePerformance['observedPercentEarlyVisitsBySchedule'] != null) ? Math.round(routePerformance['observedPercentEarlyVisitsBySchedule']) : null;
            const medianEarlinessBySchedule = (routePerformance['observedMedianEarlinessBySchedule'] != null) ? Math.round(routePerformance['observedMedianEarlinessBySchedule']) : null;
            const percentLateVisitsBySchedule = (routePerformance['observedPercentLateVisitsBySchedule'] != null) ? Math.round(routePerformance['observedPercentLateVisitsBySchedule']) : null;
            const medianLatenessBySchedule = (routePerformance['observedMedianLatenessBySchedule'] != null) ? Math.round(routePerformance['observedMedianLatenessBySchedule']) : null;
            const percentRealTimeTripDataCoverage = (routePerformance['percentRealTimeTripDataCoverage'] != null) ? Math.round(routePerformance['percentRealTimeTripDataCoverage']) : null;
            const performanceScore = getPerformanceScore(routePerformance['observedPercentOnTimeVisitsByHeadway'], routePerformance['observedPercentOnTimeVisitsBySchedule']);

            routeCards.push(
                <Flipped
                    key={routeId}
                    flipId={routeId}
                >
                    <div style={{margin: '0 1em 4em'}}>
                        <RouteCard
                            routeId={routeId}
                            percentOnTimeVisitsByHeadway={percentOnTimeVisitsByHeadway}
                            percentBunchedVisits={percentBunchedVisits}
                            percentLateVisitsByHeadway={percentLateVisitsByHeadway}
                            medianLatenessByHeadway={medianLatenessByHeadway}
                            medianObservedHeadway={medianObservedHeadway}
                            medianScheduledHeadway={medianScheduledHeadway}
                            percentOnTimeVisitsBySchedule={percentOnTimeVisitsBySchedule}
                            percentEarlyVisitsBySchedule={percentEarlyVisitsBySchedule}
                            medianEarlinessBySchedule={medianEarlinessBySchedule}
                            percentLateVisitsBySchedule={percentLateVisitsBySchedule}
                            medianLatenessBySchedule={medianLatenessBySchedule}
                            percentRealTimeTripDataCoverage={percentRealTimeTripDataCoverage}
                            performanceScore={performanceScore}
                        />
                    </div>
                </Flipped>
            );

            if (sortedFilteredRoutePerformanceDataArray.length > 0) {
                let routeIds = sortedFilteredRoutePerformanceDataArray.map(route => route.routeId);

                pageContent = (
                    <Flipper
                        flipKey={routeIds.join("_")}
                    >
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}>
                            {routeCards}
                        </div>
                    </Flipper>
                );
            }
        }

        let lastUpdatedContent;
        if (lastUpdated) {
            lastUpdatedContent = (
                <div style={{display: 'inline-block'}}>
                    last updated <strong>{moment(lastUpdated).fromNow()}</strong>
                    <span>&nbsp;｜&nbsp;</span>
                </div>
            );
        }

        const updateFrequencyContent = (
            <div style={{display: 'inline-block'}}>
                data automatically updates every 30 seconds
            </div>
        );

        if (lastUpdatedContent) {
            recentnessData = (
                <>
                    {lastUpdatedContent}
                    {updateFrequencyContent}
                </>
            );
        }

        if (Object.keys(rawPerformanceData['routes']).length !== 1) {
            searchAndFilterBar = (
                <div
                    className="columns searchAndFilterBar"
                >
                    <div
                        className="column"
                    >
                        <SearchBox
                            filterString={routeNameFilter}
                            setFilterString={setRouteNameFilter}
                        />
                    </div>

                    <div
                        className="sortBy column"
                    >
                        <SortByOptions
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                            sortDirection={sortDirection}
                            toggleSortDirection={toggleSortDirection}
                        />
                    </div>
                </div>
            );
        }

        let formattedSystemPerformanceData = {
            systemName: systemName
        };

        if (rawPerformanceData['percentRealTimeTripDataCoverage'] > 0) {
            formattedSystemPerformanceData = Object.assign(formattedSystemPerformanceData, {
                percentOnTimeVisitsByHeadway: (rawPerformanceData['observedPercentOnTimeVisitsByHeadway'] != null) ? Math.round(rawPerformanceData['observedPercentOnTimeVisitsByHeadway']) : null,
                percentBunchedVisits: (rawPerformanceData['observedPercentBunchedVisits'] != null) ? Math.round(rawPerformanceData['observedPercentBunchedVisits']) : null,
                percentLateVisitsByHeadway: (rawPerformanceData['observedPercentLateVisitsByHeadway'] != null) ? Math.round(rawPerformanceData['observedPercentLateVisitsByHeadway']) : null,
                medianLatenessByHeadway: (rawPerformanceData['observedMedianLatenessByHeadway'] != null) ? Math.round(rawPerformanceData['observedMedianLatenessByHeadway']) : null,
                medianObservedHeadway: (rawPerformanceData['observedMedianObservedHeadway'] != null) ? Math.round(rawPerformanceData['observedMedianObservedHeadway']) : null,
                medianScheduledHeadway: (rawPerformanceData['medianScheduledHeadway'] != null) ? Math.round(rawPerformanceData['medianScheduledHeadway']) : null,
                percentOnTimeVisitsBySchedule: (rawPerformanceData['observedPercentOnTimeVisitsBySchedule'] != null) ? Math.round(rawPerformanceData['observedPercentOnTimeVisitsBySchedule']) : null,
                percentEarlyVisitsBySchedule: (rawPerformanceData['observedPercentEarlyVisitsBySchedule'] != null) ? Math.round(rawPerformanceData['observedPercentEarlyVisitsBySchedule']) : null,
                medianEarlinessBySchedule: (rawPerformanceData['observedMedianEarlinessBySchedule'] != null) ? Math.round(rawPerformanceData['observedMedianEarlinessBySchedule']) : null,
                percentLateVisitsBySchedule: (rawPerformanceData['observedPercentLateVisitsBySchedule'] != null) ? Math.round(rawPerformanceData['observedPercentLateVisitsBySchedule']) : null,
                medianLatenessBySchedule: (rawPerformanceData['observedMedianLatenessBySchedule'] != null) ? Math.round(rawPerformanceData['observedMedianLatenessBySchedule']) : null,
                percentRealTimeTripDataCoverage: (rawPerformanceData['percentRealTimeTripDataCoverage'] != null) ? Math.round(rawPerformanceData['percentRealTimeTripDataCoverage']) : null,
                performanceScore: getPerformanceScore(rawPerformanceData['observedPercentOnTimeVisitsByHeadway'], rawPerformanceData['observedPercentOnTimeVisitsBySchedule']),
                numScheduledVisits: (rawPerformanceData['numScheduledVisits'] != null) ? Math.round(rawPerformanceData['numScheduledVisits']) : null,
                numScheduledVisitsTracked: (rawPerformanceData['numScheduledVisitsTracked'] != null) ? Math.round(rawPerformanceData['numScheduledVisitsTracked']) : null
            });
        }

        systemCard = (
            <SystemCard
                systemName={formattedSystemPerformanceData.systemName}
                showSystemName={false}
                percentOnTimeVisitsByHeadway={formattedSystemPerformanceData.percentOnTimeVisitsByHeadway}
                percentBunchedVisits={formattedSystemPerformanceData.percentBunchedVisits}
                percentLateVisitsByHeadway={formattedSystemPerformanceData.percentLateVisitsByHeadway}
                medianLatenessByHeadway={formattedSystemPerformanceData.medianLatenessByHeadway}
                medianObservedHeadway={formattedSystemPerformanceData.medianObservedHeadway}
                medianScheduledHeadway={formattedSystemPerformanceData.medianScheduledHeadway}
                percentOnTimeVisitsBySchedule={formattedSystemPerformanceData.percentOnTimeVisitsBySchedule}
                percentEarlyVisitsBySchedule={formattedSystemPerformanceData.percentEarlyVisitsBySchedule}
                medianEarlinessBySchedule={formattedSystemPerformanceData.medianEarlinessBySchedule}
                percentLateVisitsBySchedule={formattedSystemPerformanceData.percentLateVisitsBySchedule}
                medianLatenessBySchedule={formattedSystemPerformanceData.medianLatenessBySchedule}
                percentRealTimeTripDataCoverage={formattedSystemPerformanceData.percentRealTimeTripDataCoverage}
                performanceScore={formattedSystemPerformanceData.performanceScore}
                numScheduledVisits={formattedSystemPerformanceData.numScheduledVisits}
                numScheduledVisitsTracked={formattedSystemPerformanceData.numScheduledVisitsTracked}
            />
        );

        systemPerformanceScoreColorName = getPerformanceScoreColorName(formattedSystemPerformanceData.performanceScore);
    }

    return (
        <>
            <div
                className={`system-header ${systemPerformanceScoreColorName}`}
            >
                <div>
                    <Link className="back-link" to="/" title="Back to systems">Back to systems</Link>
                </div>
                <div className="system-name">
                    <AutoTextSize
                        mode={'oneline'}
                        maxFontSizePx={42}
                    >
                        {systemName}
                    </AutoTextSize>
                </div>
            </div>
            {
                rawPerformanceData ?
                <>
                    <div className="system-header-overlay">
                        {systemCard}
                    </div>

                    <div className="recentness-data"
                         style={{
                             marginTop: '-8em',
                             marginBottom: '2em'
                         }}>
                        <div>
                            {recentnessData}
                        </div>
                    </div>

                    <div>
                        {searchAndFilterBar}
                    </div>

                    <div
                        className="container"
                        style={{
                            flex: '1 1 auto',
                            minHeight: numResultsMessage ? 48 : 12
                        }}
                    >
                        {numResultsMessage}
                    </div>
                </>
                : null
            }


            <div
                style={{
                    margin: '0 auto'
                }}
            >
                {pageContent}
            </div>
        </>
    );
}
