import React from 'react';

import SvgIcon from "@material-ui/core/SvgIcon";

export default function SocialIconKoFi() {
    return (
        <SvgIcon viewBox="0 0 800 800">
            <path d="M796.033 298.267C770.267 162.1 634.067 145.167 634.067 145.167H24.1C3.96668 145.167 1.46668 171.767 1.46668 171.767C1.46668 171.767 -1.26665 415.9 0.73335 565.833C6.20002 646.633 86.9333 654.9 86.9333 654.9C86.9333 654.9 362.5 654.133 485.8 653.267C567.067 639.067 575.233 567.733 574.4 528.8C719.467 536.8 821.8 434.433 796.033 298.267ZM427.3 415.3C385.767 463.733 293.6 547.833 293.6 547.833C293.6 547.833 289.567 551.8 283.267 548.6C280.733 546.7 279.667 545.6 279.667 545.6C264.9 530.9 167.4 443.967 145.2 413.8C121.567 381.633 110.5 323.8 142.167 290.133C173.867 256.467 242.333 253.933 287.6 303.7C287.6 303.7 339.767 244.3 403.2 271.6C466.667 298.933 464.267 371.967 427.3 415.3ZM633.067 431.233C602.133 435.1 577 432.167 577 432.167V242.8H636C636 242.8 701.7 261.167 701.7 330.733C701.7 394.5 668.867 419.633 633.067 431.233Z"/>
        </SvgIcon>
    );
}
