import React from 'react';
import {useNavigate} from "react-router-dom";
import {AutoTextSize} from 'auto-text-size';

import SignalWifi0BarIcon from '@material-ui/icons/SignalWifi0Bar';
import SignalWifi1BarIcon from '@material-ui/icons/SignalWifi1Bar';
import SignalWifi2BarIcon from '@material-ui/icons/SignalWifi2Bar';
import SignalWifi3BarIcon from '@material-ui/icons/SignalWifi3Bar';
import SignalWifi4BarIcon from '@material-ui/icons/SignalWifi4Bar';

import {MHTooltip, TOOLTIP_INDICATOR} from "./MHTooltip";
import SystemCardSection from "./SystemCardSection";
import SystemCardSectionItem from "./SystemCardSectionItem";
import {getPerformanceScoreColorName} from "./Util";

function _renderMetricValue(metricValue, shouldIncludePercentSuffix) {
    return (metricValue != null) ? `${metricValue}${shouldIncludePercentSuffix ? "%" : ""}` : "N/A";
}

export default function SystemCard(props) {
    const navigate = useNavigate();

    const {
        systemName,
        showSystemName,
        percentOnTimeVisitsByHeadway,
        percentBunchedVisits,
        percentLateVisitsByHeadway,
        medianLatenessByHeadway,
        percentOnTimeVisitsBySchedule,
        percentEarlyVisitsBySchedule,
        medianEarlinessBySchedule,
        percentLateVisitsBySchedule,
        medianLatenessBySchedule,
        percentRealTimeTripDataCoverage,
        performanceScore,
        numScheduledVisits,
        numScheduledVisitsTracked
    } = props;

    let icon;
    if (percentRealTimeTripDataCoverage >= 95) {
        icon = <SignalWifi4BarIcon fontSize={"small"}/>;
    } else if (percentRealTimeTripDataCoverage >= 75) {
        icon = <SignalWifi3BarIcon fontSize={"small"}/>;
    } else if (percentRealTimeTripDataCoverage >= 50) {
        icon = <SignalWifi2BarIcon fontSize={"small"}/>;
    } else if (percentRealTimeTripDataCoverage >= 25) {
        icon = <SignalWifi1BarIcon fontSize={"small"}/>;
    } else {
        icon = <SignalWifi0BarIcon fontSize={"small"}/>;
    }

    let innerCardContent;
    if (!numScheduledVisits || !numScheduledVisitsTracked) {
        innerCardContent = (
            <div style={{
                display: 'flex',
                flexGrow: '1',
                justifyContent: 'center',
                alignItems: 'center',
                fontStyle: 'italic',
                padding: '2em'
            }}>
                There is currently no {!numScheduledVisits ? 'schedule' : 'real-time'} data available for this system.
            </div>
        );
    } else {
        innerCardContent = (
            <>
                <div className="column system-card-section">
                    <SystemCardSection
                        headingText="Headway Adherence"
                        data={_renderMetricValue(percentOnTimeVisitsByHeadway, true)}
                        tooltipHeader="Headway Adherence"
                        tooltipBody="Percent of most recent scheduled visits to each transit stop that occurred within 3 minutes of the scheduled time between the visit and the previous visit to the same transit stop."
                        tooltipFooter="Higher is better."
                        colorName={getPerformanceScoreColorName(percentOnTimeVisitsByHeadway)}
                    />
                    <SystemCardSection
                        headingText="Schedule Adherence"
                        data={_renderMetricValue(percentOnTimeVisitsBySchedule, true)}
                        tooltipHeader="Schedule Adherence"
                        tooltipBody="Percent of most recent scheduled visits to each transit stop that occurred between 2 minutes early and 7 minutes late compared to the scheduled time of the visit."
                        tooltipFooter="Higher is better."
                        colorName={getPerformanceScoreColorName(percentOnTimeVisitsBySchedule)}
                    />
                </div>

                <div className="column system-card-section-item-container">
                    <SystemCardSectionItem
                        firstHeadingContent={
                            <>
                                <span>
                                    <strong>{_renderMetricValue(percentBunchedVisits, true)} bunched</strong>
                                </span>
                            </>
                        }
                        firstHeadingTooltipHeader="Bunched"
                        firstHeadingTooltipBody="Percent of most recent scheduled visits to each transit stop that occurred within 25% of the scheduled time between the visit and the previous visit to the same transit stop."
                        firstHeadingTooltipFooter="Lower is better."
                        secondHeadingContent={
                            <span>
                                <strong>{_renderMetricValue(percentLateVisitsByHeadway, true)} over-spaced</strong>
                            </span>
                        }
                        secondHeadingTooltipHeader="Over-spaced (aka Lateness by Headway)"
                        secondHeadingTooltipBody="Percent of most recent scheduled visits to each transit stop that occurred more than 3 minutes later than the scheduled time between the visit and the previous visit to the same transit stop."
                        secondHeadingTooltipFooter="Lower is better."
                        secondHeadingAddendumText={
                            `by ${medianLatenessByHeadway} minutes`
                        }
                    />
                    <SystemCardSectionItem
                        firstHeadingContent={
                            <>
                                <span>
                                    <strong>{_renderMetricValue(percentEarlyVisitsBySchedule, true)} ahead</strong>
                                </span>
                            </>
                        }
                        firstHeadingTooltipHeader="Ahead (aka Earliness by Schedule)"
                        firstHeadingTooltipBody="Percent of most recent scheduled visits to each transit stop that occurred more than 2 minutes earlier than the scheduled time of the visit."
                        firstHeadingTooltipFooter="Lower is better."
                        firstHeadingAddendumText={
                            `by ${medianEarlinessBySchedule} minutes`
                        }
                        secondHeadingContent={
                            <>
                                <span>
                                    <strong>{_renderMetricValue(percentLateVisitsBySchedule, true)} behind</strong>
                                </span>
                            </>
                        }
                        secondHeadingTooltipHeader="Behind (aka Lateness by Schedule)"
                        secondHeadingTooltipBody="Percent of most recent scheduled visits to each transit stop that occurred more than 7 minutes later than the scheduled time of the visit."
                        secondHeadingTooltipFooter="Lower is better."
                        secondHeadingAddendumText={
                            `by ${medianLatenessBySchedule} minutes`
                        }
                    />
                </div>

                <div
                    className="num-reporting"
                    style={{
                        display: 'flex',
                        flexGrow: '1',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end'
                    }}
                >
                    <div className="percent-reporting">
                        <div>
                            {icon}
                        </div>
                        <div
                            style={{
                                paddingLeft: '4px'
                            }}
                        >
                            <strong>{_renderMetricValue(percentRealTimeTripDataCoverage, true)}</strong> data integrity
                        </div>
                        <MHTooltip
                            header="Data Integrity"
                            body="Percent of transit trips scheduled to be running right now that we've observed are actually running. A missing trip can occur when a vehicle is not running when it's scheduled to, or when a vehicle is actually running but is not reporting its position properly due to human error or equipment failure."
                            footer="Higher is better."
                        />
                    </div>
                </div>
            </>
        );
    }

    const systemLogo = (
        <div
            className={`system-logo ${showSystemName ? 'with-system-marker' : 'without-system-marker'}`}
        >
            <img
                src={`/system-logos/${systemName}.svg`}
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    verticalAlign: 'middle'
                }}
                alt=''
                onError={(e) => {
                    e.target.style.display = 'none';
                }}
            />
        </div>
    );

    let cardContent;
    if (showSystemName) {
        cardContent = (
            <>
                <div
                    className="row"
                    style={{
                        position: 'relative',
                        margin: '0.5em'
                    }}
                >
                    {systemLogo}
                    <div
                        className="system-marker"
                    >
                        <AutoTextSize
                            mode={'box'}
                            minFontSizePx={26}
                            maxFontSizePx={32}
                        >
                            {systemName}
                        </AutoTextSize>
                    </div>

                    {innerCardContent}
                </div>
            </>
        );
    } else {
        cardContent = (
            <>
                <div
                    className="row"
                    style={{
                        position: 'relative',
                        margin: '0.5em'
                    }}
                >
                    {systemLogo}
                    {innerCardContent}
                </div>
            </>
        );
    }

    const isLink = (performanceScore && showSystemName);

    return (
        <div
            className={`system-card ${isLink ? "is-link" : ""} ${getPerformanceScoreColorName(performanceScore)} ${(performanceScore != null) ? (showSystemName ? 'clickable' : '') : 'not-clickable'}`}
            onClick={isLink ? (e) => {
                if (e && e.target && (e.target.innerText === TOOLTIP_INDICATOR)) {
                    // user clicked on the tooltip in this card, not the card itself
                    e.preventDefault();
                    return;
                }

                navigate(`/systems/${systemName}/routes`);
            } : undefined}
        >
            {cardContent}
        </div>
    );
}
