import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import ExternalLink from "./ExternalLink";
import FAQ from "./FAQ";
import NavigationBar from "./NavigationBar";
import Systems from "./Systems";
import SystemRoutes from "./SystemRoutes";
import Trends from "./Trends";
import Reports from "./Reports";
import VehicleHistory from "./VehicleHistory";
import StopHistory from "./StopHistory";

import BusAndStopGraphic from "./svgs/BusAndStopGraphic";

import './sass/main.scss';

export default function App() {
    return (
        <Router>
            <div
                className="App"
            >
                <NavigationBar />

                <div className="main-content">
                    <Routes>
                        <Route path="/systems/:systemName/routes" element={<SystemRoutes />} />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/trends" element={<Trends />} />
                        <Route path="/reports" element={<Reports />} />
                        <Route path="/history/vehicle" element={<VehicleHistory />} />
                        <Route path="/history/stop" element={<StopHistory />} />
                        <Route path="/" element={<Systems />} />
                    </Routes>
                </div>

                <div className="footer">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: 24
                        }}
                    >
                        <BusAndStopGraphic />
                    </div>
                    <div className="footer-text">
                        <div>
                            <div style={{display: 'inline-block'}}>
                                made with nerdiness by&nbsp;
                            </div>
                            <div style={{display: 'inline-block'}}>
                                <ExternalLink className="normal" url="http://jamespizzurro.com/" title="James' LinkedIn Profile">James</ExternalLink> and <ExternalLink className="normal" url="https://www.linkedin.com/in/jenpizzurro" title="Jen's LinkedIn Profile">Jen</ExternalLink> Pizzurro
                            </div>
                        </div>

                        <div style={{margin: '0.5em 0 1em 0', fontSize: '0.9em'}}>
                            <ExternalLink url="https://x.com/ariesfortransit" title="ARIES for Transit's Twitter Page">Twitter / 𝕏</ExternalLink>&nbsp;·&nbsp;
                            <ExternalLink url="https://ko-fi.com/ariesfortransit" title="ARIES for Transit's Ko-fi Page">Ko-fi</ExternalLink>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
}
