import React from 'react';
import Dropdown from 'react-dropdown';

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import MHComponent from "./MHComponent";

import 'react-dropdown/style.css';

export default class SystemCardSection extends MHComponent {

    render() {
        const {
            sortBy,
            setSortBy,
            sortDirection,
            toggleSortDirection
        } = this.props;

        const options = [
            { value: 'routeId', label: 'Route name' },
            { value: 'observedMedianObservedHeadway', label: 'Observed headways' },
            { value: 'medianScheduledHeadway', label: 'Scheduled headways' },
            { value: 'observedPercentOnTimeVisitsByHeadway', label: 'Headway adherence' },
            { value: 'observedPercentOnTimeVisitsBySchedule', label: 'Schedule adherence' },
            { value: 'observedPercentBunchedVisits', label: '% bunched' },
            { value: 'observedPercentLateVisitsByHeadway', label: '% over-spaced' },
            { value: 'observedMedianLatenessByHeadway', label: 'Minutes over-spaced' },
            { value: 'observedPercentEarlyVisitsBySchedule', label: '% ahead of schedule' },
            { value: 'observedMedianEarlinessBySchedule', label: 'Minutes ahead of schedule' },
            { value: 'observedPercentLateVisitsBySchedule', label: '% behind schedule' },
            { value: 'observedMedianLatenessBySchedule', label: 'Minutes behind schedule' },
            { value: 'percentRealTimeTripDataCoverage', label: '% scheduled vehicles reporting' }
        ];

        return (
            <div
                style={{
                    minWidth: 300
                }}
            >
                <div
                    style={{
                        minWidth: '55px',
                        marginRight: '0.5em',
                        marginBottom: '0.5em'
                    }}
                >
                    Sort by
                </div>

                <div
                    className="sortBy-dropdown has-addons"
                >
                    <button
                        className="button sortBy-toggle"
                        onClick={toggleSortDirection}
                    >
                        {(sortDirection === "asc") ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    </button>

                    <Dropdown
                        className="dropdown"
                        options={options}
                        onChange={data => setSortBy(data.value)}
                        value={sortBy}
                        placeholder="Select an option"
                    />
                </div>
            </div>
        );
    }
}
