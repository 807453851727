import React from 'react';

import MHComponent from "./MHComponent";
import MHTooltip from "./MHTooltip";

export default class RouteCardSection extends MHComponent {

    render() {
        const {
            headingIcon,
            headingText,
            data,
            tooltipHeader,
            tooltipBody,
            tooltipFooter,
            colorName
        } = this.props;

        return (
            <div
                className="stat"
            >
                <div style={{"paddingBottom": "0.35em"}}>
                    {headingIcon}
                    <div
                        className="heading main"
                    >
                        {headingText}
                    </div>
                </div>
                <div
                    className={`stat-percent ${colorName}`}
                >
                    <div
                        className={`${(data === "N/A") ? "not-available" : ""}`}
                        style={{
                            display: 'inline-block'
                        }}
                    >
                        {data}
                    </div>
                    <MHTooltip
                        header={tooltipHeader}
                        body={tooltipBody}
                        footer={tooltipFooter}
                    />
                </div>
            </div>
        );
    }
}
