import React from "react";
import MHComponent from "./MHComponent";

export default class ExternalLink extends MHComponent {

    render() {
        const {
            className,
            url,
            title,
            style,
            children
        } = this.props;

        return (
            <a className={className} href={url} title={title} target="_blank" rel="noopener noreferrer" style={style}>
                {children}
            </a>
        );
    }
}