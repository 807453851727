import moment from "moment";
import React, {useEffect, useState} from "react";
import ReactGA from "react-ga4";

import PageLoadingSpinner from "./PageLoadingSpinner";
import SystemCard from "./SystemCard";
import {getPerformanceScore} from "./Util";

export default function Systems() {

    const [initialized, setInitialized] = useState(false);

    const [systemPerformanceData, setSystemPerformanceData] = useState([]);

    useEffect(() => {
        // reset scroll
        window.scrollTo(0, 0);

        // on mount
        ReactGA.initialize('G-ZN33QTNMS7');
        ReactGA.pageview(window.location.pathname);

        return () => {
            // on unmount
            const timerId = localStorage.getItem("timerId");
            if (timerId) {
                clearTimeout(parseInt(timerId, 10));
            }
        };
    }, []);

    useEffect(() => {
        async function fetchData(shouldFetchData) {
            let refreshInterval;
            try {
                if (shouldFetchData) {
                    const timerId = localStorage.getItem("timerId");
                    if (timerId) {
                        clearTimeout(parseInt(timerId, 10));
                    }

                    const res = await fetch('/api/v1/performance/live');
                    const text = await res.text();
                    const json = JSON.parse(text);

                    let systemPerformanceData = [];

                    for (const [systemName, performanceData] of Object.entries(json)) {
                        let formattedPerformanceData = {
                            systemName: systemName
                        };

                        if (performanceData) {
                            formattedPerformanceData = Object.assign(formattedPerformanceData, {
                                numScheduledVisits: (performanceData['numScheduledVisits'] != null) ? Math.round(performanceData['numScheduledVisits']) : null,
                                numScheduledVisitsTracked: (performanceData['numScheduledVisitsTracked'] != null) ? Math.round(performanceData['numScheduledVisitsTracked']) : null,
                                lastUpdated: performanceData['lastUpdated']
                            });

                            if (performanceData['percentRealTimeTripDataCoverage'] > 0) {
                                formattedPerformanceData = Object.assign(formattedPerformanceData, {
                                    percentOnTimeVisitsByHeadway: (performanceData['observedPercentOnTimeVisitsByHeadway'] != null) ? Math.round(performanceData['observedPercentOnTimeVisitsByHeadway']) : null,
                                    percentBunchedVisits: (performanceData['observedPercentBunchedVisits'] != null) ? Math.round(performanceData['observedPercentBunchedVisits']) : null,
                                    percentLateVisitsByHeadway: (performanceData['observedPercentLateVisitsByHeadway'] != null) ? Math.round(performanceData['observedPercentLateVisitsByHeadway']) : null,
                                    medianLatenessByHeadway: (performanceData['observedMedianLatenessByHeadway'] != null) ? Math.round(performanceData['observedMedianLatenessByHeadway']) : null,
                                    medianObservedHeadway: (performanceData['observedMedianObservedHeadway'] != null) ? Math.round(performanceData['observedMedianObservedHeadway']) : null,
                                    medianScheduledHeadway: (performanceData['medianScheduledHeadway'] != null) ? Math.round(performanceData['medianScheduledHeadway']) : null,
                                    percentOnTimeVisitsBySchedule: (performanceData['observedPercentOnTimeVisitsBySchedule'] != null) ? Math.round(performanceData['observedPercentOnTimeVisitsBySchedule']) : null,
                                    percentEarlyVisitsBySchedule: (performanceData['observedPercentEarlyVisitsBySchedule'] != null) ? Math.round(performanceData['observedPercentEarlyVisitsBySchedule']) : null,
                                    medianEarlinessBySchedule: (performanceData['observedMedianEarlinessBySchedule'] != null) ? Math.round(performanceData['observedMedianEarlinessBySchedule']) : null,
                                    percentLateVisitsBySchedule: (performanceData['observedPercentLateVisitsBySchedule'] != null) ? Math.round(performanceData['observedPercentLateVisitsBySchedule']) : null,
                                    medianLatenessBySchedule: (performanceData['observedMedianLatenessBySchedule'] != null) ? Math.round(performanceData['observedMedianLatenessBySchedule']) : null,
                                    percentRealTimeTripDataCoverage: (performanceData['percentRealTimeTripDataCoverage'] != null) ? Math.round(performanceData['percentRealTimeTripDataCoverage']) : null,
                                    performanceScore: getPerformanceScore(performanceData['observedPercentOnTimeVisitsByHeadway'], performanceData['observedPercentOnTimeVisitsBySchedule'])
                                });
                            }
                        }

                        systemPerformanceData.push(formattedPerformanceData);
                    }

                    systemPerformanceData = systemPerformanceData.sort((a, b) => {
                        // primary sort in descending order by performance score
                        // secondary sort in descending order by system name

                        if (a.performanceScore !== b.performanceScore) {
                            return ((b.performanceScore || 0) - (a.performanceScore || 0));
                        } else {
                            return a.systemName.localeCompare(b.systemName, 'en', {numeric: true});
                        }
                    });
                    setSystemPerformanceData(systemPerformanceData);
                }

                refreshInterval = 30000;
            } catch (err) {
                refreshInterval = 5000;
            } finally {
                if (shouldFetchData && refreshInterval) {
                    const timerId = setTimeout(fetchData, refreshInterval, true);
                    localStorage.setItem("timerId", timerId.toString());
                }

                setInitialized(true);
            }
        }

        setTimeout(fetchData, 0, !initialized);
    }, [initialized]);

    let systemCards;
    let recentnessContent;

    if (systemPerformanceData.length <= 0) {
        systemCards = (
            <div className="container">
                <PageLoadingSpinner />
            </div>
        );
    } else {
        let lastUpdated;

        systemCards = [];

        for (const performanceData of systemPerformanceData) {
            const lastUpdatedCandidate = performanceData.lastUpdated ? moment(performanceData.lastUpdated) : null;
            if (lastUpdatedCandidate && (!lastUpdated || lastUpdatedCandidate.isAfter(lastUpdated))) {
                lastUpdated = lastUpdatedCandidate;
            }

            systemCards.push(
                <div
                    key={performanceData.systemName}
                    className="system-card-wrapper"
                >
                    <SystemCard
                        systemName={performanceData.systemName}
                        showSystemName={true}
                        percentOnTimeVisitsByHeadway={performanceData.percentOnTimeVisitsByHeadway}
                        percentBunchedVisits={performanceData.percentBunchedVisits}
                        percentLateVisitsByHeadway={performanceData.percentLateVisitsByHeadway}
                        medianLatenessByHeadway={performanceData.medianLatenessByHeadway}
                        medianObservedHeadway={performanceData.medianObservedHeadway}
                        medianScheduledHeadway={performanceData.medianScheduledHeadway}
                        percentOnTimeVisitsBySchedule={performanceData.percentOnTimeVisitsBySchedule}
                        percentEarlyVisitsBySchedule={performanceData.percentEarlyVisitsBySchedule}
                        medianEarlinessBySchedule={performanceData.medianEarlinessBySchedule}
                        percentLateVisitsBySchedule={performanceData.percentLateVisitsBySchedule}
                        medianLatenessBySchedule={performanceData.medianLatenessBySchedule}
                        percentRealTimeTripDataCoverage={performanceData.percentRealTimeTripDataCoverage}
                        performanceScore={performanceData.performanceScore}
                        numScheduledVisits={performanceData.numScheduledVisits}
                        numScheduledVisitsTracked={performanceData.numScheduledVisitsTracked}
                    />
                </div>
            );
        }

        if (lastUpdated) {
            recentnessContent = (
                <div
                    className="recentness-data"
                    style={{
                        marginBottom: '2em',
                        padding: 'initial'
                    }}
                >
                    <div>
                        <div style={{display: 'inline-block'}}>
                            last updated <strong>{lastUpdated.fromNow()}</strong>
                            <span>&nbsp;｜&nbsp;</span>
                        </div>
                        <div style={{display: 'inline-block'}}>
                            data automatically updates every 30 seconds
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="container" style={{margin: '0 auto'}}>
            <div style={{textAlign: 'center', margin: '1em auto 2em'}}>
                <h1 className="page-title"><span>A</span>dherence + <span>R</span>eliability + <span>I</span>ntegrity <span>E</span>valuation <span>S</span>ystem</h1>

                <p style={{fontSize: '1.25em'}}>We measure the operational performance of transit systems in real time!</p>
                <p style={{fontSize: '1.25em'}}>Tap or click one of the transit systems listed below for route-level details.</p>
            </div>
            {recentnessContent}
            {systemCards}
        </div>
    );
}
