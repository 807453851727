import React from 'react';

import SvgIcon from "@material-ui/core/SvgIcon";

function HeadwayAdherenceIcon() {
  return (
    <SvgIcon viewBox="0 0 512 512">
      <g>
        <g>
          <path d="M400,368c-20.864,0-38.464,13.408-45.056,32H157.056c-6.624-18.592-24.192-32-45.056-32c-26.496,0-48,21.504-48,48
          c0,26.496,21.504,48,48,48c20.864,0,38.464-13.408,45.056-32h197.888c6.624,18.592,24.192,32,45.056,32c26.496,0,48-21.504,48-48
          C448,389.504,426.496,368,400,368z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M112,48C50.24,48,0,98.24,0,160c0,57.472,89.856,159.264,100.096,170.688c3.04,3.36,7.36,5.312,11.904,5.312
          s8.864-1.952,11.904-5.312C134.144,319.264,224,217.472,224,160C224,98.24,173.76,48,112,48z M112,208c-26.496,0-48-21.504-48-48
          c0-26.496,21.504-48,48-48c26.496,0,48,21.504,48,48C160,186.496,138.496,208,112,208z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M400,48c-61.76,0-112,50.24-112,112c0,57.472,89.856,159.264,100.096,170.688c3.04,3.36,7.36,5.312,11.904,5.312
          s8.864-1.952,11.904-5.312C422.144,319.264,512,217.472,512,160C512,98.24,461.76,48,400,48z M400,208c-26.496,0-48-21.504-48-48
          c0-26.496,21.504-48,48-48c26.496,0,48,21.504,48,48C448,186.496,426.496,208,400,208z"/>
        </g>
      </g>
    </SvgIcon>
  );
}

export default HeadwayAdherenceIcon;
