import moment from "moment";
import Papa from "papaparse";
import React from 'react';

import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';

import ExternalLink from "./ExternalLink";
import MHComponent from "./MHComponent";

ChartJS.register(CategoryScale, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip);

class OperationalPerformanceChart extends MHComponent {

    render() {
        const {
            data,
            shouldIncludeSystemAndRouteName,
            type
        } = this.props;

        if (!data) {
            return false;
        }

        const {
            systemName,
            routeId,
            graphType,
            labels,
            lastUpdated,

            // trips
            numScheduledTrips,
            numScheduledTripsTracked,

            // headways
            observedMedianObservedHeadway,
            medianScheduledHeadway,

            // crowding
            observedPercentVisitsFull,
            optimisticPercentVisitsFull,
            pessimisticPercentVisitsFull,
            percentRealTimeTripDataCoverage,

            // otp
            observedPercentOnTimeVisitsByHeadway,
            observedPercentOnTimeVisitsBySchedule,
            optimisticPercentOnTimeVisitsBySchedule,
            pessimisticPercentOnTimeVisitsBySchedule,

            // earliness
            observedPercentBunchedVisits,
            observedPercentEarlyVisitsBySchedule,
            optimisticPercentEarlyVisitsBySchedule,
            pessimisticPercentEarlyVisitsBySchedule,

            // lateness
            observedPercentLateVisitsByHeadway,
            observedPercentLateVisitsBySchedule,
            optimisticPercentLateVisitsBySchedule,
            pessimisticPercentLateVisitsBySchedule
        } = data;

        let titlePrefix;
        let isPointInTimeData;
        let titleSuffix;
        let formattedLabels;
        switch (graphType) {
            case "past-hour":
                isPointInTimeData = true;
                titleSuffix = "Past Hour";
                formattedLabels = labels.map(label => moment.utc(label,'h:mma').local().format("h:mma"));
                break;
            case "past-3-hours":
                isPointInTimeData = true;
                titleSuffix = "Past 3 Hours";
                formattedLabels = labels.map(label => moment.utc(label,'h:mma').local().format("h:mma"));
                break;
            case "past-6-hours":
                isPointInTimeData = true;
                titleSuffix = "Past 6 Hours";
                formattedLabels = labels.map(label => moment.utc(label,'h:mma').local().format("h:mma"));
                break;
            case "past-12-hours":
                isPointInTimeData = true;
                titleSuffix = "Past 12 Hours";
                formattedLabels = labels.map(label => moment.utc(label,'h:mma').local().format("h:mma"));
                break;
            case "past-24-hours":
                isPointInTimeData = true;
                titleSuffix = "Past 24 Hours";
                formattedLabels = labels.map(label => moment.utc(label,'h:mma').local().format("h:mma"));
                break;
            case "day-over-week":
                titlePrefix = "Daily";
                isPointInTimeData = false;
                titleSuffix = "Past Week";
                formattedLabels = labels;
                break;
            case "day-over-month":
                titlePrefix = "Daily";
                isPointInTimeData = false;
                titleSuffix = "Past Month";
                formattedLabels = labels;
                break;
            case "week-over-month":
                titlePrefix = "Weekly";
                isPointInTimeData = false;
                titleSuffix = "Past Month";
                formattedLabels = labels;
                break;
            case "week-over-quarter":
                titlePrefix = "Weekly";
                isPointInTimeData = false;
                titleSuffix = "Past Quarter";
                formattedLabels = labels;
                break;
            case "month-over-quarter":
                titlePrefix = "Monthly";
                isPointInTimeData = false;
                titleSuffix = "Past Quarter";
                formattedLabels = labels;
                break;
            case "month-over-year":
                titlePrefix = "Monthly";
                isPointInTimeData = false;
                titleSuffix = "Past Year";
                formattedLabels = labels;
                break;
            default:
                return false;
        }

        let chart;
        let chartSpecificDetails;
        let dataToDownload;
        switch (type) {
            case "trips":
                chart = (
                    <Line
                        redraw
                        data={{
                            labels: formattedLabels,
                            datasets: [
                                {
                                    label: "Observed",
                                    backgroundColor: "rgb(75, 192, 255, 0.5)",
                                    borderColor: "rgb(75, 192, 255)",
                                    hoverBorderColor: "rgb(175, 192, 255)",
                                    fill: 0,
                                    tension: 0,
                                    data: numScheduledTripsTracked
                                },
                                {
                                    label: "Scheduled",
                                    backgroundColor: "rgb(0, 0, 0, 0.5)",
                                    borderColor: "rgb(0, 0, 0, 0.5)",
                                    hoverBorderColor: "rgb(0, 0, 0, 0.5)",
                                    fill: false,
                                    tension: 0,
                                    data: numScheduledTrips
                                }
                            ]
                        }}
                        options={{
                            scales: {
                                x: {
                                    ticks: {
                                        font: {
                                            size: 10
                                        }
                                    }
                                },
                                y: {
                                    beginAtZero: true,
                                    ticks: {
                                        font: {
                                            size: 10
                                        },
                                        precision: 0
                                    },
                                    title: {
                                        display: true,
                                        text: "active trips"
                                    }
                                },
                                y1: {
                                    position: 'right',
                                    ticks: {
                                        display: false
                                    },
                                    grid: {
                                        display: false
                                    },
                                    title: {
                                        display: true,
                                        text: " "
                                    }
                                }
                            },
                            plugins: {
                                title: {
                                    display: true,
                                    font: {
                                        size: 18
                                    },
                                    text: `${titlePrefix ? `${titlePrefix} ` : ""}${isPointInTimeData ? "Point-in-time " : ""}${shouldIncludeSystemAndRouteName ? (systemName + (routeId ? ` ${routeId} ` : " ")) : ""}Trips over the ${titleSuffix}`
                                },
                                legend: {
                                    labels: {
                                        padding: 16,
                                        usePointStyle: true
                                    }
                                },
                                tooltip: {
                                    mode: "nearest",
                                    axis: "x",
                                    intersect: false,
                                    itemSort: function (a, b) {
                                        return b.raw - a.raw;
                                    },
                                    callbacks: {
                                        label: function (tooltipItem) {
                                            let label = tooltipItem.dataset.label || "";

                                            if (label) {
                                                label += ": ";
                                            }
                                            label += `${Math.round(tooltipItem.raw)} trips`;

                                            return label;
                                        }
                                    }
                                }
                            },
                            maintainAspectRatio: false
                        }}
                    />
                );
                dataToDownload = formattedLabels.map((label, index) => ({
                    '': label,
                    numScheduledTrips: numScheduledTrips[index],
                    numScheduledTripsTracked: numScheduledTripsTracked[index]
                }));
                break;
            case "headways":
                chart = (
                    <Line
                        redraw
                        data={{
                            labels: formattedLabels,
                            datasets: [
                                {
                                    label: "Observed",
                                    backgroundColor: "rgb(75, 192, 255, 0.5)",
                                    borderColor: "rgb(75, 192, 255)",
                                    hoverBorderColor: "rgb(175, 192, 255)",
                                    fill: false,
                                    tension: 0,
                                    data: observedMedianObservedHeadway
                                },
                                {
                                    label: "Scheduled",
                                    backgroundColor: "rgb(0, 0, 0, 0.5)",
                                    borderColor: "rgb(0, 0, 0, 0.5)",
                                    hoverBorderColor: "rgb(0, 0, 0, 0.5)",
                                    fill: false,
                                    tension: 0,
                                    data: medianScheduledHeadway
                                }
                            ]
                        }}
                        options={{
                            scales: {
                                x: {
                                    ticks: {
                                        font: {
                                            size: 10
                                        }
                                    }
                                },
                                y: {
                                    beginAtZero: true,
                                    ticks: {
                                        font: {
                                            size: 10
                                        },
                                        precision: 0
                                    },
                                    title: {
                                        display: true,
                                        text: "median headway (in minutes)"
                                    }
                                },
                                y1: {
                                    position: 'right',
                                    ticks: {
                                        display: false
                                    },
                                    grid: {
                                        display: false
                                    },
                                    title: {
                                        display: true,
                                        text: " "
                                    }
                                }
                            },
                            plugins: {
                                title: {
                                    display: true,
                                    font: {
                                        size: 18
                                    },
                                    text: `${titlePrefix ? `${titlePrefix} ` : ""}${isPointInTimeData ? "Point-in-time " : ""}${shouldIncludeSystemAndRouteName ? (systemName + (routeId ? ` ${routeId} ` : " ")) : ""}Headways over the ${titleSuffix}`
                                },
                                legend: {
                                    labels: {
                                        padding: 16,
                                        usePointStyle: true
                                    }
                                },
                                tooltip: {
                                    mode: "nearest",
                                    axis: "x",
                                    intersect: false,
                                    itemSort: function (a, b) {
                                        return b.raw - a.raw;
                                    },
                                    callbacks: {
                                        label: function (tooltipItem) {
                                            let label = tooltipItem.dataset.label || "";

                                            if (label) {
                                                label += ": ";
                                            }
                                            label += `${Math.round(tooltipItem.raw)} minutes`;

                                            return label;
                                        }
                                    }
                                }
                            },
                            maintainAspectRatio: false
                        }}
                    />
                );
                dataToDownload = formattedLabels.map((label, index) => ({
                    '': label,
                    observedMedianObservedHeadway: observedMedianObservedHeadway[index],
                    medianScheduledHeadway: medianScheduledHeadway[index]
                }));
                break;
            case "otp":
                chart = (
                    <Line
                        redraw
                        data={{
                            labels: formattedLabels,
                            datasets: [
                                {
                                    label: "Headway Adherence",
                                    backgroundColor: "rgb(75, 192, 255, 0.5)",
                                    borderColor: "rgb(75, 192, 255)",
                                    hoverBorderColor: "rgb(175, 192, 255)",
                                    fill: false,
                                    tension: 0,
                                    data: observedPercentOnTimeVisitsByHeadway
                                },
                                {
                                    label: "Schedule Adherence",
                                    backgroundColor: "rgb(255, 75, 75, 0.5)",
                                    borderColor: "rgb(255, 75, 75)",
                                    hoverBorderColor: "rgb(255, 75, 75)",
                                    fill: false,
                                    tension: 0,
                                    data: observedPercentOnTimeVisitsBySchedule
                                },
                                {
                                    label: "Optimistic Schedule Adherence",
                                    backgroundColor: "rgb(255, 75, 75, 0.25)",
                                    borderColor: "transparent",
                                    pointRadius: 0,
                                    fill: 1,
                                    tension: 0,
                                    data: optimisticPercentOnTimeVisitsBySchedule
                                },
                                {
                                    label: "Pessimistic Schedule Adherence",
                                    backgroundColor: "rgb(255, 75, 75, 0.25)",
                                    borderColor: "transparent",
                                    pointRadius: 0,
                                    fill: 1,
                                    tension: 0,
                                    data: pessimisticPercentOnTimeVisitsBySchedule
                                },
                                {
                                    label: "Data Integrity",
                                    pointStyle: "line",
                                    backgroundColor: "rgb(0, 0, 0, 0.5)",
                                    borderColor: "rgb(0, 0, 0, 0.5)",
                                    hoverBorderColor: "rgb(0, 0, 0, 0.5)",
                                    borderWidth: 2,
                                    borderDash: [5, 5],
                                    pointRadius: 0,
                                    fill: false,
                                    tension: 0,
                                    data: percentRealTimeTripDataCoverage
                                }
                            ]
                        }}
                        options={{
                            scales: {
                                x: {
                                    ticks: {
                                        font: {
                                            size: 10
                                        }
                                    }
                                },
                                y: {
                                    beginAtZero: true,
                                    max: 100,
                                    ticks: {
                                        font: {
                                            size: 10
                                        },
                                        precision: 0,
                                        callback: function(label, index, labels) {
                                            return `${label}%`;
                                        }
                                    },
                                    title: {
                                        display: true,
                                        text: "transit stop visits"
                                    }
                                },
                                y1: {
                                    position: 'right',
                                    ticks: {
                                        display: false
                                    },
                                    grid: {
                                        display: false
                                    },
                                    title: {
                                        display: true,
                                        text: " "
                                    }
                                }
                            },
                            plugins: {
                                title: {
                                    display: true,
                                    font: {
                                        size: 18
                                    },
                                    text: `${titlePrefix ? `${titlePrefix} ` : ""}${isPointInTimeData ? "Point-in-time " : ""}${shouldIncludeSystemAndRouteName ? (systemName + (routeId ? ` ${routeId} ` : " ")) : ""}Performance over the ${titleSuffix}`
                                },
                                legend: {
                                    labels: {
                                        padding: 16,
                                        usePointStyle: true,
                                        filter: function(item, chart) {
                                            return !!item.text && !item.text.includes("Optimistic") && !item.text.includes("Pessimistic");
                                        }
                                    }
                                },
                                tooltip: {
                                    mode: "nearest",
                                    axis: "x",
                                    intersect: false,
                                    itemSort: function (a, b) {
                                        return b.raw - a.raw;
                                    },
                                    callbacks: {
                                        label: function (tooltipItem) {
                                            let label = tooltipItem.dataset.label || "";

                                            if (label) {
                                                label += ": ";
                                            }
                                            label += `${Math.round(tooltipItem.raw)}%`;

                                            if (label.includes("Optimistic") || label.includes("Pessimistic")) {
                                                label += "*";
                                            }

                                            return label;
                                        }
                                    }
                                }
                            },
                            maintainAspectRatio: false
                        }}
                    />
                );
                dataToDownload = formattedLabels.map((label, index) => ({
                    '': label,
                    observedPercentOnTimeVisitsByHeadway: observedPercentOnTimeVisitsByHeadway[index],
                    observedPercentOnTimeVisitsBySchedule: observedPercentOnTimeVisitsBySchedule[index],
                    optimisticPercentOnTimeVisitsBySchedule: optimisticPercentOnTimeVisitsBySchedule[index],
                    pessimisticPercentOnTimeVisitsBySchedule: pessimisticPercentOnTimeVisitsBySchedule[index],
                    percentRealTimeTripDataCoverage: percentRealTimeTripDataCoverage[index]
                }));
                break;
            case "crowding":
                chart = (
                    <Line
                        redraw
                        data={{
                            labels: formattedLabels,
                            datasets: [
                                {
                                    label: '% Visits Made by "Full" Vehicle',
                                    backgroundColor: "rgb(75, 192, 255, 0.5)",
                                    borderColor: "rgb(75, 192, 255)",
                                    hoverBorderColor: "rgb(175, 192, 255)",
                                    fill: false,
                                    tension: 0,
                                    data: observedPercentVisitsFull
                                },
                                {
                                    label: 'Optimistic % Visits Made by "Full" Vehicle',
                                    backgroundColor: "rgb(75, 192, 255, 0.25)",
                                    borderColor: "transparent",
                                    pointRadius: 0,
                                    fill: 0,
                                    tension: 0,
                                    data: optimisticPercentVisitsFull
                                },
                                {
                                    label: 'Pessimistic % Visits Made by "Full" Vehicle',
                                    backgroundColor: "rgb(75, 192, 255, 0.25)",
                                    borderColor: "transparent",
                                    pointRadius: 0,
                                    fill: 0,
                                    tension: 0,
                                    data: pessimisticPercentVisitsFull
                                },
                                {
                                    label: "Data Integrity",
                                    pointStyle: "line",
                                    backgroundColor: "rgb(0, 0, 0, 0.5)",
                                    borderColor: "rgb(0, 0, 0, 0.5)",
                                    hoverBorderColor: "rgb(0, 0, 0, 0.5)",
                                    borderWidth: 2,
                                    borderDash: [5, 5],
                                    pointRadius: 0,
                                    fill: false,
                                    tension: 0,
                                    data: percentRealTimeTripDataCoverage
                                }
                            ]
                        }}
                        options={{
                            scales: {
                                x: {
                                    ticks: {
                                        font: {
                                            size: 10
                                        }
                                    }
                                },
                                y: {
                                    beginAtZero: true,
                                    max: 100,
                                    ticks: {
                                        font: {
                                            size: 10
                                        },
                                        precision: 0,
                                        callback: function(label, index, labels) {
                                            return `${label}%`;
                                        }
                                    },
                                    title: {
                                        display: true,
                                        text: "transit stop visits"
                                    }
                                },
                                y1: {
                                    position: 'right',
                                    ticks: {
                                        display: false
                                    },
                                    grid: {
                                        display: false
                                    },
                                    title: {
                                        display: true,
                                        text: " "
                                    }
                                }
                            },
                            plugins: {
                                title: {
                                    display: true,
                                    font: {
                                        size: 18
                                    },
                                    text: `${titlePrefix ? `${titlePrefix} ` : ""}${isPointInTimeData ? "Point-in-time " : ""}${shouldIncludeSystemAndRouteName ? (systemName + (routeId ? ` ${routeId} ` : " ")) : ""}Vehicle Crowding over the ${titleSuffix}`
                                },
                                legend: {
                                    labels: {
                                        padding: 16,
                                        usePointStyle: true,
                                        filter: function(item, chart) {
                                            return !!item.text && !item.text.includes("Optimistic") && !item.text.includes("Pessimistic");
                                        }
                                    }
                                },
                                tooltip: {
                                    mode: "nearest",
                                    axis: "x",
                                    intersect: false,
                                    itemSort: function (a, b) {
                                        return b.raw - a.raw;
                                    },
                                    callbacks: {
                                        label: function (tooltipItem) {
                                            let label = tooltipItem.dataset.label || "";

                                            if (label) {
                                                label += ": ";
                                            }
                                            label += `${Math.round(tooltipItem.raw)}%`;

                                            if (label.includes("Optimistic") || label.includes("Pessimistic")) {
                                                label += "*";
                                            }

                                            return label;
                                        }
                                    }
                                }
                            },
                            maintainAspectRatio: false
                        }}
                    />
                );
                chartSpecificDetails = (
                    <>
                        <ExternalLink className="normal" url="https://developers.google.com/transit/gtfs-realtime/reference#enum-occupancystatus">occupancy levels</ExternalLink> that determine "fullness" are defined by each transit agency
                        <br/><br/>
                    </>
                )
                dataToDownload = formattedLabels.map((label, index) => ({
                    '': label,
                    observedPercentVisitsFull: observedPercentVisitsFull[index],
                    optimisticPercentVisitsFull: optimisticPercentVisitsFull[index],
                    pessimisticPercentVisitsFull: pessimisticPercentVisitsFull[index],
                    percentRealTimeTripDataCoverage: percentRealTimeTripDataCoverage[index]
                }));
                break;
            case "earliness":
                chart = (
                    <Line
                        redraw
                        data={{
                            labels: formattedLabels,
                            datasets: [
                                {
                                    label: "Bunched (by headway)",
                                    backgroundColor: "rgb(75, 192, 255, 0.5)",
                                    borderColor: "rgb(75, 192, 255)",
                                    hoverBorderColor: "rgb(175, 192, 255)",
                                    fill: false,
                                    tension: 0,
                                    data: observedPercentBunchedVisits
                                },
                                {
                                    label: "Early (by schedule)",
                                    backgroundColor: "rgb(255, 75, 75, 0.5)",
                                    borderColor: "rgb(255, 75, 75)",
                                    hoverBorderColor: "rgb(255, 75, 75)",
                                    fill: false,
                                    tension: 0,
                                    data: observedPercentEarlyVisitsBySchedule
                                },
                                {
                                    label: "Optimistically Early (by schedule)",
                                    backgroundColor: "rgb(255, 75, 75, 0.25)",
                                    borderColor: "transparent",
                                    pointRadius: 0,
                                    fill: 1,
                                    tension: 0,
                                    data: optimisticPercentEarlyVisitsBySchedule
                                },
                                {
                                    label: "Pessimistically Early (by schedule)",
                                    backgroundColor: "rgb(255, 75, 75, 0.25)",
                                    borderColor: "transparent",
                                    pointRadius: 0,
                                    fill: 1,
                                    tension: 0,
                                    data: pessimisticPercentEarlyVisitsBySchedule
                                },
                                {
                                    label: "Data Integrity",
                                    pointStyle: "line",
                                    backgroundColor: "rgb(0, 0, 0, 0.5)",
                                    borderColor: "rgb(0, 0, 0, 0.5)",
                                    hoverBorderColor: "rgb(0, 0, 0, 0.5)",
                                    borderWidth: 2,
                                    borderDash: [5, 5],
                                    pointRadius: 0,
                                    fill: false,
                                    tension: 0,
                                    data: percentRealTimeTripDataCoverage
                                }
                            ]
                        }}
                        options={{
                            scales: {
                                x: {
                                    ticks: {
                                        font: {
                                            size: 10
                                        }
                                    }
                                },
                                y: {
                                    beginAtZero: true,
                                    max: 100,
                                    ticks: {
                                        font: {
                                            size: 10
                                        },
                                        precision: 0,
                                        callback: function(label, index, labels) {
                                            return `${label}%`;
                                        }
                                    },
                                    title: {
                                        display: true,
                                        text: "transit stop visits"
                                    }
                                },
                                y1: {
                                    position: 'right',
                                    ticks: {
                                        display: false
                                    },
                                    grid: {
                                        display: false
                                    },
                                    title: {
                                        display: true,
                                        text: " "
                                    }
                                }
                            },
                            plugins: {
                                title: {
                                    display: true,
                                    font: {
                                        size: 18
                                    },
                                    text: `${titlePrefix ? `${titlePrefix} ` : ""}${isPointInTimeData ? "Point-in-time " : ""}${shouldIncludeSystemAndRouteName ? (systemName + (routeId ? ` ${routeId} ` : " ")) : ""}Earliness over the ${titleSuffix}`
                                },
                                legend: {
                                    labels: {
                                        padding: 16,
                                        usePointStyle: true,
                                        filter: function(item, chart) {
                                            return !!item.text && !item.text.includes("Optimistic") && !item.text.includes("Pessimistic");
                                        }
                                    }
                                },
                                tooltip: {
                                    mode: "nearest",
                                    axis: "x",
                                    intersect: false,
                                    itemSort: function (a, b) {
                                        return b.raw - a.raw;
                                    },
                                    callbacks: {
                                        label: function (tooltipItem) {
                                            let label = tooltipItem.dataset.label || "";

                                            if (label) {
                                                label += ": ";
                                            }
                                            label += `${Math.round(tooltipItem.raw)}%`;

                                            if (label.includes("Optimistic") || label.includes("Pessimistic")) {
                                                label += "*";
                                            }

                                            return label;
                                        }
                                    }
                                }
                            },
                            maintainAspectRatio: false
                        }}
                    />
                );
                dataToDownload = formattedLabels.map((label, index) => ({
                    '': label,
                    observedPercentBunchedVisits: observedPercentBunchedVisits[index],
                    observedPercentEarlyVisitsBySchedule: observedPercentEarlyVisitsBySchedule[index],
                    optimisticPercentEarlyVisitsBySchedule: optimisticPercentEarlyVisitsBySchedule[index],
                    pessimisticPercentEarlyVisitsBySchedule: pessimisticPercentEarlyVisitsBySchedule[index],
                    percentRealTimeTripDataCoverage: percentRealTimeTripDataCoverage[index]
                }));
                break;
            case "lateness":
                chart = (
                    <Line
                        redraw
                        data={{
                            labels: formattedLabels,
                            datasets: [
                                {
                                    label: "Late (by headway)",
                                    backgroundColor: "rgb(75, 192, 255, 0.5)",
                                    borderColor: "rgb(75, 192, 255)",
                                    hoverBorderColor: "rgb(175, 192, 255)",
                                    fill: false,
                                    tension: 0,
                                    data: observedPercentLateVisitsByHeadway
                                },
                                {
                                    label: "Late (by schedule)",
                                    backgroundColor: "rgb(255, 75, 75, 0.5)",
                                    borderColor: "rgb(255, 75, 75)",
                                    hoverBorderColor: "rgb(255, 75, 75)",
                                    fill: false,
                                    tension: 0,
                                    data: observedPercentLateVisitsBySchedule
                                },
                                {
                                    label: "Optimistically Late (by schedule)",
                                    backgroundColor: "rgb(255, 75, 75, 0.25)",
                                    borderColor: "transparent",
                                    pointRadius: 0,
                                    fill: 1,
                                    tension: 0,
                                    data: optimisticPercentLateVisitsBySchedule
                                },
                                {
                                    label: "Pessimistically Late (by schedule)",
                                    backgroundColor: "rgb(255, 75, 75, 0.25)",
                                    borderColor: "transparent",
                                    pointRadius: 0,
                                    fill: 1,
                                    tension: 0,
                                    data: pessimisticPercentLateVisitsBySchedule
                                },
                                {
                                    label: "Data Integrity",
                                    pointStyle: "line",
                                    backgroundColor: "rgb(0, 0, 0, 0.5)",
                                    borderColor: "rgb(0, 0, 0, 0.5)",
                                    hoverBorderColor: "rgb(0, 0, 0, 0.5)",
                                    borderWidth: 2,
                                    borderDash: [5, 5],
                                    pointRadius: 0,
                                    fill: false,
                                    tension: 0,
                                    data: percentRealTimeTripDataCoverage
                                }
                            ]
                        }}
                        options={{
                            scales: {
                                x: {
                                    ticks: {
                                        font: {
                                            size: 10
                                        }
                                    }
                                },
                                y: {
                                    beginAtZero: true,
                                    max: 100,
                                    ticks: {
                                        font: {
                                            size: 10
                                        },
                                        precision: 0,
                                        callback: function(label, index, labels) {
                                            return `${label}%`;
                                        }
                                    },
                                    title: {
                                        display: true,
                                        text: "transit stop visits"
                                    }
                                },
                                y1: {
                                    position: 'right',
                                    ticks: {
                                        display: false
                                    },
                                    grid: {
                                        display: false
                                    },
                                    title: {
                                        display: true,
                                        text: " "
                                    }
                                }
                            },
                            plugins: {
                                title: {
                                    display: true,
                                    font: {
                                        size: 18
                                    },
                                    text: `${titlePrefix ? `${titlePrefix} ` : ""}${isPointInTimeData ? "Point-in-time " : ""}${shouldIncludeSystemAndRouteName ? (systemName + (routeId ? ` ${routeId} ` : " ")) : ""}Lateness over the ${titleSuffix}`
                                },
                                legend: {
                                    labels: {
                                        padding: 16,
                                        usePointStyle: true,
                                        filter: function(item, chart) {
                                            return !!item.text && !item.text.includes("Optimistic") && !item.text.includes("Pessimistic");
                                        }
                                    }
                                },
                                tooltip: {
                                    mode: "nearest",
                                    axis: "x",
                                    intersect: false,
                                    itemSort: function (a, b) {
                                        return b.raw - a.raw;
                                    },
                                    callbacks: {
                                        label: function (tooltipItem) {
                                            let label = tooltipItem.dataset.label || "";

                                            if (label) {
                                                label += ": ";
                                            }
                                            label += `${Math.round(tooltipItem.raw)}%`;

                                            if (label.includes("Optimistic") || label.includes("Pessimistic")) {
                                                label += "*";
                                            }

                                            return label;
                                        }
                                    }
                                }
                            },
                            maintainAspectRatio: false
                        }}
                    />
                );
                dataToDownload = formattedLabels.map((label, index) => ({
                    '': label,
                    observedPercentLateVisitsByHeadway: observedPercentLateVisitsByHeadway[index],
                    observedPercentLateVisitsBySchedule: observedPercentLateVisitsBySchedule[index],
                    optimisticPercentLateVisitsBySchedule: optimisticPercentLateVisitsBySchedule[index],
                    pessimisticPercentLateVisitsBySchedule: pessimisticPercentLateVisitsBySchedule[index],
                    percentRealTimeTripDataCoverage: percentRealTimeTripDataCoverage[index]
                }));
                break;
            default:
                return false;
        }

        return (
            <div
                style={{
                    textAlign: 'left',
                    minWidth: 300 - 48,
                    maxWidth: 800,
                    margin: '0 auto'
                }}
            >
                <div
                    style={{
                        minWidth: 300 - 48,
                        width: 'calc(100vw - 48px)',
                        maxWidth: 800,
                        minHeight: 300 - 48,
                        height: 'calc(100vw - 48px)',
                        maxHeight: 600,
                        margin: '0 auto'
                    }}
                >
                    {chart}
                </div>
                <p
                    className="chartDetails"
                >
                    {chartSpecificDetails}

                    Optimistic values assume perfect adherence for all unreported transit stop visits missing from the data,
                    whereas Pessimistic values assume the opposite; the shaded area represents all possible scenarios between the best and worst cases.
                    See <ExternalLink className="normal" url="/faq#visits">our FAQs</ExternalLink> for more information.
                </p>
                <button
                    style={{
                        display: 'block',
                        margin: '8px auto'
                    }}
                    onClick={() => {
                        const csvString = Papa.unparse(dataToDownload);
                        const element = document.createElement('a');
                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvString));
                        element.setAttribute('download', `ariesTrends-${systemName}-${routeId ? routeId : '(all)'}-${type}-${graphType}-${moment(lastUpdated).unix()}.csv`);
                        element.style.display = 'none';
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                    }}
                >
                    Download CSV
                </button>
            </div>
        );
    }
}

export default OperationalPerformanceChart;
