import React from 'react';

import MHComponent from "./MHComponent";
import MHTooltip from "./MHTooltip";

export default class RouteCardSectionItem extends MHComponent {

    render() {
        const {
            firstHeadingContent,
            firstHeadingTooltipHeader,
            firstHeadingTooltipBody,
            firstHeadingTooltipFooter,
            firstHeadingAddendumText,
            secondHeadingContent,
            secondHeadingTooltipHeader,
            secondHeadingTooltipBody,
            secondHeadingTooltipFooter,
            secondHeadingAddendumText
        } = this.props;

        let firstHeadingAddendum;
        if (firstHeadingAddendumText && !firstHeadingAddendumText.includes('null')) {
            firstHeadingAddendum = (
                <div>
                    {firstHeadingAddendumText}
                </div>
            );
        }

        let secondHeadingAddendum;
        if (secondHeadingAddendumText && !secondHeadingAddendumText.includes('null')) {
            secondHeadingAddendum = (
                <div>
                    {secondHeadingAddendumText}
                </div>
            );
        }

        return (
            <div
                className="stat"
            >
                <div className="route-card-section-item">
                    <div>
                        <span
                            className="heading"
                            style={{
                                display: 'inline-block'
                            }}
                        >
                            {firstHeadingContent}
                        </span>
                        <MHTooltip
                            header={firstHeadingTooltipHeader}
                            body={firstHeadingTooltipBody}
                            footer={firstHeadingTooltipFooter}
                        />
                    </div>
                    <div style={{
                        fontStyle: 'italic',
                        color: '#434343'
                    }}>
                        {firstHeadingAddendum}
                    </div>
                </div>

                <div className="route-card-section-item">
                    <div>
                        <span
                            className="heading"
                            style={{
                                display: 'inline-block'
                            }}
                        >
                            {secondHeadingContent}
                        </span>
                        <MHTooltip
                            header={secondHeadingTooltipHeader}
                            body={secondHeadingTooltipBody}
                            footer={secondHeadingTooltipFooter}
                        />
                    </div>
                    <div style={{
                        fontStyle: 'italic',
                        color: '#434343'
                    }}>
                        {secondHeadingAddendum}
                    </div>
                </div>
            </div>
        );
    }
}
