import React from 'react';

import SearchIcon from "@material-ui/icons/Search";

export default function SearchBox(props) {

    const {
        filterString,
        setFilterString
    } = props;

    function onChange(event) {
        setFilterString(event.target.value.toString().toUpperCase());
    }

    return (
        <div style={{minWidth: 300}}>
            <div
                style={{
                    minWidth: '55px',
                    marginRight: '0.5em',
                    marginBottom: '0.5em'
                }}
            >Search for Route</div>
            <div
                className="searchBox control has-icon"
            >
                <span
                    className="icon is-small is-left"
                >
                  <SearchIcon />
                </span>
                <input
                    style={{width:'100%'}}
                    className="input"
                    type="text"
                    placeholder="Search for a route"
                    value={filterString}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}
