import React from "react";
import MDSpinner from "react-md-spinner";

import MHComponent from "./MHComponent";

export default class PageLoadingSpinner extends MHComponent {

    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <MDSpinner
                    style={{
                        margin: 16
                    }}
                    size={64}
                    singleColor={'#40c4ff'}
                />
            </div>
        );
    }
}