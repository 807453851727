import React from 'react';

import MHComponent from "./MHComponent";
import MHTooltip from "./MHTooltip";

export default class SystemCardSection extends MHComponent {

    render() {
        const {
            headingText,
            data,
            tooltipHeader,
            tooltipBody,
            tooltipFooter,
            colorName
        } = this.props;

        return (
            <div style={{
                flexGrow: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <div
                    className={`stat-percent ${colorName}`}
                >
                    <span
                        className={`${(data === "N/A") ? "not-available" : ""}`}
                        style={{
                            textTransform: 'uppercase',
                            textAlign: 'right',
                            fontSize: '0.35em',
                            width: '6.5em',
                            paddingRight: '0.5em',
                            letterSpacing: '-1px'
                        }}
                    >
                        {headingText}
                    </span>

                    <div
                        className={`${(data === "N/A") ? "not-available" : ""}`}
                        style={{
                            display: 'inline-block'
                        }}
                    >
                        {data}
                    </div>
                    <MHTooltip
                        header={tooltipHeader}
                        body={tooltipBody}
                        footer={tooltipFooter}
                    />
                </div>
            </div>
        );
    }
}
