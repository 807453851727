export function getPerformanceScore(percentOnTimeVisitsByHeadway, percentOnTimeVisitsBySchedule) {
    const scores = [];

    if (percentOnTimeVisitsByHeadway != null) {
        scores.push(percentOnTimeVisitsByHeadway);
    }

    if (percentOnTimeVisitsBySchedule != null) {
        scores.push(percentOnTimeVisitsBySchedule);
    }

    if (scores.length > 0) {
        // compute average score from scores
        const numerator = scores.reduce((accumulator, currentValue) => accumulator + currentValue);
        const denominator = scores.length;
        return Math.round(numerator / denominator);
    } else {
        return null;
    }
}

export function getPerformanceScoreColorName(score) {
    if (score == null) {
        return "gray";
    } else if (score >= 90) {
        return "green";
    } else if (score >= 80) {
        return "greenish";
    } else if (score >= 70) {
        return "yellow";
    } else if (score >= 60) {
        return "orange";
    } else {
        return "red";
    }
}