import React, {useEffect, useState} from "react";
import ReactGA from "react-ga4";

import ExternalLink from "./ExternalLink";
import PageLoadingSpinner from "./PageLoadingSpinner";

export default function Reports() {
    const [reportData, setReportData] = useState(null);

    useEffect(() => {
        // reset scroll
        window.scrollTo(0, 0);

        // on mount
        ReactGA.initialize('G-ZN33QTNMS7');
        ReactGA.pageview(window.location.pathname);

        const startMillis = (new Date()).getTime();

        async function fetchData() {
            let refreshInterval;
            try {
                const timerId = localStorage.getItem("timerId");
                if (timerId) {
                    clearTimeout(parseInt(timerId, 10));
                }

                const res = await fetch('/api/v1/reports/performance/monthly');
                const text = await res.text();
                const json = JSON.parse(text);

                const endMillis = (new Date()).getTime();

                setTimeout(() => {
                    setReportData(json);
                }, Math.max(500 - (endMillis - startMillis), 0));  // make sure users always see the loading spinner, but not more than they need to
            } catch (err) {
                refreshInterval = 5000;
            } finally {
                if (refreshInterval) {
                    const timerId = setTimeout(fetchData, refreshInterval, true);
                    localStorage.setItem("timerId", timerId.toString());
                }
            }
        }

        setTimeout(fetchData, 1000);

        return () => {
            // on unmount
            const timerId = localStorage.getItem("timerId");
            if (timerId) {
                clearTimeout(parseInt(timerId, 10));
            }
        };
    }, []);

    let pageContent;
    if (!reportData) {
        pageContent = <PageLoadingSpinner />;
    } else {
        const performanceReportLinks = [];

        for (const systemName in reportData) {
            const listItems = [];

            const fileNameByReportName = reportData[systemName];
            for (const reportName in fileNameByReportName) {
                const fileName = fileNameByReportName[reportName];

                listItems.push(
                    <div
                        key={reportName}
                    >
                        <ExternalLink
                            className="normal"
                            url={`/reports/performance/monthly/${fileName}`}
                        >
                            {reportName}
                        </ExternalLink>
                    </div>
                );
            }

            performanceReportLinks.push(
                <div
                    key={systemName}
                >
                    <h3>{systemName}</h3>
                    <div>
                        {listItems}
                    </div>
                </div>
            );
        }

        pageContent = performanceReportLinks;
    }

    return (
        <div
            className="Reports container"
            style={{
                height: '100%',
                textAlign: 'center'
            }}
        >
            <h2>Monthly Performance Reports</h2>
            <p
                style={{
                    maxWidth: 640
                }}
            >
                At the beginning of every month, a performance report is automatically generated for each transit system and its routes using data from the previous month.
                Each PDF linked below contains a system-wide report followed by individual route-level reports for each transit route.
                Please reference the appendices at the end of every PDF for important details on our metrics, assumptions, and methodology.
            </p>
            {pageContent}
        </div>
    );
}