import React from 'react';

import MHComponent from "./MHComponent";
import MHTooltip from "./MHTooltip";

export default class SystemCardSectionItem extends MHComponent {

    render() {
        const {
            firstHeadingContent,
            firstHeadingTooltipHeader,
            firstHeadingTooltipBody,
            firstHeadingTooltipFooter,
            firstHeadingAddendumText,
            secondHeadingContent,
            secondHeadingTooltipHeader,
            secondHeadingTooltipBody,
            secondHeadingTooltipFooter,
            secondHeadingAddendumText
        } = this.props;

        let firstHeadingAddendum;
        if (firstHeadingAddendumText && !firstHeadingAddendumText.includes('null')) {
            firstHeadingAddendum = (
                <span className="addendum-text">
                    {firstHeadingAddendumText}
                </span>
            );
        }

        let secondHeadingAddendum;
        if (secondHeadingAddendumText && !secondHeadingAddendumText.includes('null')) {
            secondHeadingAddendum = (
                <span className="addendum-text">
                    {secondHeadingAddendumText}
                </span>
            );
        }

        return (
            <div className="system-card-section-item">
                <div>
                    <span
                        className="heading"
                        style={{
                            display: 'inline-block'
                        }}
                    >
                        {firstHeadingContent}
                        <MHTooltip
                            header={firstHeadingTooltipHeader}
                            body={firstHeadingTooltipBody}
                            footer={firstHeadingTooltipFooter}
                        />
                    </span>
                    {firstHeadingAddendum}
                </div>

                <div>
                    <span
                        className="heading"
                        style={{
                            display: 'inline-block'
                        }}
                    >
                        {secondHeadingContent}
                        <MHTooltip
                            header={secondHeadingTooltipHeader}
                            body={secondHeadingTooltipBody}
                            footer={secondHeadingTooltipFooter}
                        />
                    </span>
                    {secondHeadingAddendum}
                </div>
            </div>
        );
    }
}
