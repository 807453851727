import React from 'react';
import {AutoTextSize} from 'auto-text-size';

import SignalWifi0BarIcon from '@material-ui/icons/SignalWifi0Bar';
import SignalWifi1BarIcon from '@material-ui/icons/SignalWifi1Bar';
import SignalWifi2BarIcon from '@material-ui/icons/SignalWifi2Bar';
import SignalWifi3BarIcon from '@material-ui/icons/SignalWifi3Bar';
import SignalWifi4BarIcon from '@material-ui/icons/SignalWifi4Bar';

import MHComponent from "./MHComponent";
import MHTooltip from "./MHTooltip";
import RouteCardSection from "./RouteCardSection";
import RouteCardSectionItem from "./RouteCardSectionItem";
import {getPerformanceScoreColorName} from "./Util";

import HeadwayAdherenceIcon from "./svgs/HeadwayAdherenceIcon";
import ScheduleAdherenceIcon from "./svgs/ScheduleAdherenceIcon";

export default class RouteCard extends MHComponent {

    _renderMetricValue(metricValue, shouldIncludePercentSuffix) {
        return (metricValue != null) ? `${metricValue}${shouldIncludePercentSuffix ? "%" : ""}` : "N/A";
    }

    render() {
        const {
            routeId,
            percentOnTimeVisitsByHeadway,
            percentBunchedVisits,
            percentLateVisitsByHeadway,
            medianLatenessByHeadway,
            medianObservedHeadway,
            medianScheduledHeadway,
            percentOnTimeVisitsBySchedule,
            percentEarlyVisitsBySchedule,
            medianEarlinessBySchedule,
            percentLateVisitsBySchedule,
            medianLatenessBySchedule,
            percentRealTimeTripDataCoverage,
            performanceScore
        } = this.props;

        let icon;
        if (percentRealTimeTripDataCoverage >= 95) {
            icon = <SignalWifi4BarIcon fontSize={"small"}/>;
        } else if (percentRealTimeTripDataCoverage >= 75) {
            icon = <SignalWifi3BarIcon fontSize={"small"}/>;
        } else if (percentRealTimeTripDataCoverage >= 50) {
            icon = <SignalWifi2BarIcon fontSize={"small"}/>;
        } else if (percentRealTimeTripDataCoverage >= 25) {
            icon = <SignalWifi1BarIcon fontSize={"small"}/>;
        } else {
            icon = <SignalWifi0BarIcon fontSize={"small"}/>;
        }

        const innerCardContent = (
            <>
                <div
                    className="arrival-frequency"
                >
                    <p
                        className="heading"
                    >
                        Arriving every {this._renderMetricValue(medianObservedHeadway, false)} minutes <br className="is-hidden-tablet" />(vs. scheduled {this._renderMetricValue(medianScheduledHeadway, false)})
                    </p>
                    <hr />
                </div>
                <div
                    className="stats"
                >
                    <div
                        className="columns head-stats"
                    >
                        <RouteCardSection
                            headingIcon={
                                <HeadwayAdherenceIcon />
                            }
                            headingText="Headways"
                            data={this._renderMetricValue(percentOnTimeVisitsByHeadway, true)}
                            tooltipHeader="Headway Adherence"
                            tooltipBody="Percent of most recent scheduled visits to each transit stop that occurred within 3 minutes of the scheduled time between the visit and the previous visit to the same transit stop."
                            tooltipFooter="Higher is better."
                            colorName={getPerformanceScoreColorName(percentOnTimeVisitsByHeadway)}
                        />
                        <RouteCardSection
                            headingIcon={
                                <ScheduleAdherenceIcon />
                            }
                            headingText="Schedule"
                            data={this._renderMetricValue(percentOnTimeVisitsBySchedule, true)}
                            tooltipHeader="Schedule Adherence"
                            tooltipBody="Percent of most recent scheduled visits to each transit stop that occurred between 2 minutes early and 7 minutes late compared to the scheduled time of the visit."
                            tooltipFooter="Higher is better."
                            colorName={getPerformanceScoreColorName(percentOnTimeVisitsBySchedule)}
                        />
                    </div>
                    <div
                        className="columns small-stats"
                    >
                        <RouteCardSectionItem
                            firstHeadingContent={
                                <>
                                    <strong>
                                        {this._renderMetricValue(percentBunchedVisits, true)} bunched
                                    </strong>
                                </>
                            }
                            firstHeadingTooltipHeader="Bunched"
                            firstHeadingTooltipBody="Percent of most recent scheduled visits to each transit stop that occurred within 25% of the scheduled time between the visit and the previous visit to the same transit stop."
                            firstHeadingTooltipFooter="Lower is better."
                            secondHeadingContent={
                                <strong>
                                    {this._renderMetricValue(percentLateVisitsByHeadway, true)} over-spaced
                                </strong>
                            }
                            secondHeadingTooltipHeader="Over-spaced (aka Lateness by Headway)"
                            secondHeadingTooltipBody="Percent of most recent scheduled visits to each transit stop that occurred more than 3 minutes later than the scheduled time between the visit and the previous visit to the same transit stop."
                            secondHeadingTooltipFooter="Lower is better."
                            secondHeadingAddendumText={
                                `by ${medianLatenessByHeadway} minutes`
                            }
                        />
                        <RouteCardSectionItem
                            firstHeadingContent={
                                <>
                                    <strong>
                                        {this._renderMetricValue(percentEarlyVisitsBySchedule, true)} ahead
                                    </strong>
                                </>
                            }
                            firstHeadingTooltipHeader="Ahead (aka Earliness by Schedule)"
                            firstHeadingTooltipBody="Percent of most recent scheduled visits to each transit stop that occurred more than 2 minutes earlier than the scheduled time of the visit."
                            firstHeadingTooltipFooter="Lower is better."
                            firstHeadingAddendumText={
                                `by ${medianEarlinessBySchedule} minutes`
                            }
                            secondHeadingContent={
                                <>
                                    <strong>
                                        {this._renderMetricValue(percentLateVisitsBySchedule, true)} behind
                                    </strong>
                                </>
                            }
                            secondHeadingTooltipHeader="Behind (aka Lateness by Schedule)"
                            secondHeadingTooltipBody="Percent of most recent scheduled visits to each transit stop that occurred more than 7 minutes later than the scheduled time of the visit."
                            secondHeadingTooltipFooter="Lower is better."
                            secondHeadingAddendumText={
                                `by ${medianLatenessBySchedule} minutes`
                            }
                        />
                    </div>
                </div>
                <div
                    className="num-reporting"
                >
                    <hr/>
                    <div className="percent-reporting">
                        <div>
                            {icon}
                        </div>
                        <div
                            style={{
                                paddingLeft: '4px'
                            }}
                        >
                            {this._renderMetricValue(percentRealTimeTripDataCoverage, true)} data integrity
                        </div>
                        <MHTooltip
                            header="Data Integrity"
                            body="Percent of transit trips scheduled to be running right now that we've observed are actually running. A missing trip can occur when a vehicle is not running when it's scheduled to, or when a vehicle is actually running but is not reporting its position properly due to human error or equipment failure."
                            footer="Higher is better."
                        />
                    </div>
                </div>
            </>
        );

        const cardContent = (
            <>
                <div
                    className="route-marker"
                >
                    <div
                        className="route-id"
                    >
                        <AutoTextSize
                            mode={'oneline'}
                            maxFontSizePx={24}
                        >
                            {routeId}
                        </AutoTextSize>
                    </div>
                </div>
                <div>
                    {innerCardContent}
                </div>
            </>
        );

        return (
            <div
                className={`route-card ${getPerformanceScoreColorName(performanceScore)}`}
            >
                {cardContent}
            </div>
        );
    }
}
